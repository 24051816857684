<template>
  <div class="limit-data-size-wrap">
    <texts
      class="limit-data-size-desc"
      :text="$t('datasetList.popup.upperLimitDataSize.desc')"
      size="small"
    />
    <div class="limit-data-size-current">
      <text-with-title
        :title="$t('datasetList.popup.upperLimitDataSize.current')"
        :text="currentTotleSizeFormat"
      />
      <text-with-title
        :title="$t('datasetList.popup.upperLimitDataSize.maxSize')"
        :text="maxSizeFormat"
      />
    </div>
    <text-with-title
      :title="$t('datasetList.popup.upperLimitDataSize.formula')"
    >
      <span class="limit-data-size-formula">
        {{ dispFormula }}
        <texts :text="overFileSizeFormat" color="caution" size="small" />
      </span>
    </text-with-title>
    <div>
      <button-main
        :text="$t('datasetList.popup.upperLimitDataSize.checkPlan')"
        type="emphasis"
        @click="checkPlan"
      />
      <button-main
        :text="$t('common.close')"
        type="sub"
        @click="$emit('close-modal')"
      />
    </div>
  </div>
</template>

<script>
import textWithTitle from '@/components/molecules/text-with-title.vue'
import buttonMain from '@/components/atoms/button-main.vue'
export default {
  components: {
    textWithTitle,
    buttonMain
  },
  props: {
    sizeInfo: Object
  },
  computed: {
    currentTotleSizeFormat() {
      return this.formatFileSize(this.sizeInfo.currentTotalSize)
    },
    fileSizeFormat() {
      return this.formatFileSize(this.sizeInfo.fileSize)
    },
    maxSizeFormat() {
      return this.formatFileSize(this.sizeInfo.maxSize)
    },
    newTotalSizeFormat() {
      return this.formatFileSize(this.sizeInfo.newTotalSize)
    },
    overFileSizeFormat() {
      const over = this.sizeInfo.newTotalSize - this.sizeInfo.maxSize
      return (
        '(' +
        this.formatFileSize(over) +
        this.$t('datasetList.popup.upperLimitDataSize.overCount')
      )
    },
    dispFormula() {
      return (
        this.fileSizeFormat +
        ' + ' +
        this.currentTotleSizeFormat +
        ' = ' +
        this.newTotalSizeFormat
      )
    }
  },
  methods: {
    checkPlan() {
      location.href = 'https://www.matrixflow.net/matrixflow/contact-basic-plan'
    }
  }
}
</script>

<style lang="scss" scoped>
.limit-data-size {
  &-wrap {
    width: adjustVW(760);
  }
  &-desc {
    margin-bottom: $space-medium;
    white-space: pre-line;
  }
  &-current {
    display: flex;
    grid-column-gap: $space-medium;
    margin-bottom: $space-medium;
  }
  &-formula {
    display: flex;
    align-items: center;
    grid-column-gap: $space-base;
    margin-bottom: $space-medium;
  }
}
</style>
