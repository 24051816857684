<template v-if="projectItem">
  <card
    v-tooltip.left="{
      content: projectTooltipContents
    }"
    :row="3"
    class="project-item-card"
    :isActive="checked"
    @click="linkTo(project)"
  >
    <div class="project-item-wrap">
      <div class="project-item-title-block">
        <div class="project-item-title-block-text">
          <texts :text="project.name" size="large" />
        </div>
        <div
          v-if="!isAlreadyChecked || checked"
          v-tooltip="disabledCheckTips"
          class="project-item-title-block-checkbox"
          @click.stop
          @mouseenter="showTips = true"
          @mouseleave="showTips = false"
        >
          <checkbox-base
            :checked="checked"
            :isDisabled="disabledCheck"
            @input="inputCheck($event)"
          />
        </div>
      </div>
      <div class="project-item-text-with-title">
        <div class="project-item-info-inner">
          <text-with-title
            v-if="project.sourceTemplate"
            :text="project.sourceTemplate.name"
            :title="$t('projectDetail.templateUsed')"
          />
          <text-with-title
            v-else
            :text="$t('projectDetail.nonUseTemplate')"
            :title="$t('projectDetail.templateUsed')"
          />
        </div>
        <div class="project-item-info-inner">
          <text-with-title :title="$t('common.updateTime')">
            <a-time :value="project.updateTime" />
          </text-with-title>
        </div>
      </div>
      <div class="project-item-desc-block">
        <description :text="project.description" />
      </div>
    </div>
    <div class="project-item-tag-block">
      <tag-block :tags="project.tags" :cropCount="4" />
    </div>
  </card>
</template>
<script>
import texts from '@/components/atoms/text.vue'
import checkboxBase from '@/components/atoms/checkbox-base.vue'
import textWithTitle from '@/components/molecules/text-with-title.vue'
import description from '@/components/atoms/description.vue'
import card from '@/components/atoms/card.vue'
import tagBlock from '@/components/molecules/tag-block.vue'

export default {
  components: {
    texts,
    checkboxBase,
    textWithTitle,
    description,
    tagBlock,
    card
  },
  data() {
    return {
      showTips: false
    }
  },
  props: {
    project: Object,
    checked: Boolean,
    isAlreadyChecked: Boolean,
    disabledCheck: Boolean,
    plan: String
  },
  computed: {
    disabledCheckTips() {
      return {
        content: this.$t('common.disabled.organization.otherAccountItem'),
        trigger: ['manual'],
        show: this.disabledCheck && this.showTips,
        delay: { show: 500, hide: 500 }
      }
    },
    projectTooltipContents() {
      const trainedAi = `<div class="tooltip-item-mergin-list-item">${this.$t(
        'projectList.tip.listAIs'
      )} : ${this.project.numAIs}${this.$t('common.count')}</div>`
      const dataset = `<div class="tooltip-item-mergin-list-item">${this.$t(
        'projectList.tip.listDataset'
      )} : ${this.datasetSize}</div>`
      const recipe = `<div class="tooltip-item-mergin-list-item">${this.$t(
        'projectList.tip.listRecipe'
      )} : ${this.project.numRecipe}${this.$t('common.count')}</div>`
      const edaRecipe = `<div class="tooltip-item-mergin-list-item">${this.$t(
        'projectList.tip.listEdaRecipe'
      )} : ${this.project.numEdaRecipe}${this.$t('common.count')}</div>`

      const service = ['basic', 'enterprise_lite'].includes(this.plan)
        ? `<div class="tooltip-item-mergin-list-item">${this.$t(
            'projectList.tip.listServices'
          )} : ${this.serviceCount}${this.$t('common.count')}</div>`
        : ''
      return trainedAi + service + dataset + recipe + edaRecipe
    },
    serviceCount() {
      return this.project.nServices?.total ?? 0
    },
    datasetSize() {
      const size = this.project.dataSize
      return this.formatFileSize(size)
    }
  },
  methods: {
    linkTo(item) {
      this.$router.push({
        name: 'projectDetail',
        params: {
          projectId: item.id
        }
      })
    },
    inputCheck(e) {
      this.$emit('input', e)
    }
  }
}
</script>

<style lang="scss" scoped>
.project-item {
  &-card {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: auto;
    min-height: adjustVH(312);
  }
  &-title-block {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: adjustVW(32); // チェックボックスの高さ
    margin: 0 0 $space-small;
    &-text {
      overflow: hidden;
      flex-shrink: 1;
      width: 100%;
      margin-right: $space-small;
    }
    &-checkbox {
      flex-shrink: 0;
      width: adjustVW(24);
    }
  }
  &-text-with-title {
    display: flex;
    margin: 0 0 $space-small;
  }
  &-desc-block {
    display: flex;
    margin: 0 0 $space-medium;
  }
  &-info-inner {
    width: calc(50% - #{$space-sub});
    margin: 0 $space-medium 0 0;
    &:last-child {
      margin: 0;
    }
  }
}
</style>
