<template>
  <page-top :title="$t('projectList.title')" iconName="project">
    <!-- page-top-sub -->
    <div class="c-page-top-form-wrap">
      <div class="c-page-top-form-main">
        <div class="c-page-top-form-block">
          <fuse-search
            class="c-page-top-form-input"
            :placeholder="$t('projectList.searchBox')"
            :value.sync="projectList"
            :option="option"
            @input="$emit('input-search', $event)"
          />
        </div>
        <div class="c-page-top-form-block">
          <select-box
            v-model="order"
            class="c-page-top-form-item"
            :items="selectBox"
          />
        </div>
      </div>
      <div class="c-page-top-form-menus">
        <div class="c-page-top-form-filter">
          <texts
            class="c-page-top-form-filter-title"
            size="small"
            color="gray"
            :text="$t('projectList.filter.title')"
          />
          <checkbox-base
            v-model="hasTrain"
            class="c-page-top-form-filter-checkbox"
            :text="$t('common.trainedAI')"
          />
          <checkbox-base
            v-if="showService"
            v-model="hasService"
            class="c-page-top-form-filter-checkbox"
            :text="$t('common.service')"
          />
          <checkbox-base
            v-model="hasRecipe"
            class="c-page-top-form-filter-checkbox"
            :text="$t('common.recipe')"
          />
        </div>
        <menu-list
          :checked="checked"
          :menus="menus"
          :isMenuShow="checked.length > 0"
          @menu-click="$emit('menu-click', $event)"
        />
      </div>
    </div>
  </page-top>
</template>

<script>
import checkboxBase from '@/components/atoms/checkbox-base'
import pageTop from '@/components/atoms/page-top'
import fuseSearch from '@/components/molecules/fuse-search.vue'
import selectBox from '@/components/molecules/select-box.vue'
import menuList from '@/components/molecules/menu-list'

export default {
  components: {
    checkboxBase,
    pageTop,
    fuseSearch,
    selectBox,
    menuList
  },
  props: {
    accountInfo: Object,
    projectList: Array,
    checked: Array,
    checkTemplateUser: Boolean
  },
  data() {
    return {
      order: 'descending',
      option: {
        includeScore: true,
        shouldSort: true,
        threshold: 0.2,
        keys: [
          {
            name: 'name',
            weight: 0.5
          },
          {
            name: 'tags',
            weight: 0.5
          },
          {
            name: 'accountId',
            weight: 0.5
          }
        ],
        distance: 10000
      },
      hasTrain: false,
      hasService: false,
      hasRecipe: false
    }
  },
  computed: {
    canProjectDelete() {
      if (this.checked.length !== 1) return false
      if (this.accountInfo?.organizationInfo?.role === 'admin') return true
      const project = this.projectList.find((x) => x.id === this.checked[0])
      if (project?.accountId === this.accountInfo.accountId) {
        return true
      } else {
        return false
      }
    },
    selectBox() {
      return [
        {
          id: 0,
          name: this.$t('common.order.updateAscending'),
          value: 'ascending'
        },
        {
          id: 1,
          name: this.$t('common.order.updateDescending'),
          value: 'descending'
        }
      ]
    },
    menus() {
      let projectDelete = {
        id: 1,
        name: this.$t('common.deleteButton'),
        iconName: 'delete',
        value: 'delete',
        isMultiple: true, // 複数選択可能かどうか
        disabled: true,
        tips: {
          name: this.$t('common.deleteButton')
        }
      }
      if (this.canProjectDelete) {
        projectDelete = {
          id: 1,
          name: this.$t('common.deleteButton'),
          iconName: 'delete',
          value: 'delete',
          isMultiple: false, // 複数選択可能かどうか
          disabled: false,
          tips: {
            name: this.$t('common.deleteButton')
          }
        }
      }
      const res = [projectDelete]
      if (this.checkTemplateUser) {
        res.push({
          id: 2,
          iconName: 'project',
          value: 'template',
          isMultiple: false, // 複数選択可能かどうか
          tips: {
            name: this.$t('projectList.createTemplate')
          }
        })
      }
      return res
    },
    showService() {
      return (
        ['basic', 'enterprise_lite'].includes(this.accountInfo?.plan) &&
        this.accountInfo?.organizationInfo?.auth_services
      )
    }
  },
  watch: {
    order() {
      this.$emit('input', {
        order: this.order,
        hasTrain: this.hasTrain,
        hasService: this.hasService,
        hasRecipe: this.hasRecipe
      })
    },
    hasTrain() {
      this.$emit('input', {
        order: this.order,
        hasTrain: this.hasTrain,
        hasService: this.hasService,
        hasRecipe: this.hasRecipe
      })
    },
    hasService() {
      this.$emit('input', {
        order: this.order,
        hasTrain: this.hasTrain,
        hasService: this.hasService,
        hasRecipe: this.hasRecipe
      })
    },
    hasRecipe() {
      this.$emit('input', {
        order: this.order,
        hasTrain: this.hasTrain,
        hasService: this.hasService,
        hasRecipe: this.hasRecipe
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.c-page-top-form {
  &-wrap {
    display: flex;
    justify-content: space-between;
  }
  &-main {
    display: flex;
  }
  &-block {
    margin: 0 $space-medium 0 0;
  }
  &-item {
    width: calc(100% + #{adjustVW(40)});
  }
  &-input {
    height: 100%;
  }
  &-menus {
    display: flex;
    align-items: center;
  }
  &-filter {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: $space-sub;
    &-title {
      margin-right: $space-medium;
    }
    &-checkbox {
      padding-right: 0;
      margin: 0 $space-small 0 0;
      &:last-of-type {
        margin-right: 0;
      }
    }
    + .menu-wrap {
      margin-left: $space-medium;
    }
  }
}
</style>
