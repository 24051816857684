<template>
  <transition-toggle-contents>
    <card-base v-if="checkShowPopup && showPopup" class="community-wrap">
      <texts
        class="community-text"
        :text="$t('common.communityLink.mainText')"
        size="small"
      />
      <div class="community-items">
        <button class="community-link" rel="noopener" @click="goCommunity">
          <texts
            :text="$t('common.communityLink.buttonLink')"
            color="link"
            size="small"
          />
        </button>
        <button class="community-close-ever" @click="closePopupEver">
          <texts
            :text="$t('common.communityLink.closeText')"
            color="caution"
            size="min"
          />
        </button>
      </div>
      <button class="community-close" @click="closePopup">
        <icons iconName="close" size="min" />
      </button>
    </card-base>
  </transition-toggle-contents>
</template>

<script>
import cardBase from '@/components/atoms/card-base'
import icons from '@/components/atoms/icon'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'

export default {
  components: {
    cardBase,
    icons,
    transitionToggleContents
  },
  data() {
    return {
      showPopup: true
    }
  },
  props: {
    accountInfo: Object
  },
  methods: {
    goCommunity() {
      this.$emit('click-hidden-community-link')
      open(this.$urls.community, '_blank')
    },
    closePopup() {
      this.showPopup = false
    },
    closePopupEver() {
      this.$emit('click-hidden-community-link')
      this.showPopup = false
    }
  },
  computed: {
    checkShowPopup() {
      const check = this.accountInfo.tutorialInfo.communityLink

      if (
        !check &&
        (this.accountInfo.plan === 'basic' ||
          this.accountInfo.plan === 'enterprise_lite' ||
          this.accountInfo.plan === 'education_1')
      ) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.community {
  &-wrap {
    padding: adjustVW(20);
    border: $border-sub;
    border-radius: adjustVW(8);
    box-shadow: $box-shadow-hover;
  }
  &-text {
    margin-bottom: $space-sub;
    line-height: $line-height-base;
    white-space: pre-line;
  }
  &-items {
    display: flex;
    justify-content: space-between;
  }
  &-link {
    display: inline-block;
    @include buttonHover;
  }
  &-close {
    position: absolute;
    top: adjustVW(10);
    right: adjustVW(10);
    padding: $space-base;
    border: $border-sub;
    background: $background;
    border-radius: 9in;
    box-shadow: $box-shadow-main;
  }
}
</style>
