<template>
  <div class="limit-wrap">
    <texts
      class="limit-text"
      :text="$t('projectList.popup.overTrainedAis.text')"
    />
    <button-main
      :text="$t('projectList.popup.overTrainedAis.button')"
      type="emphasis"
      @click="checkPlan"
    />
    <button-main
      :text="$t('common.close')"
      type="sub"
      @click="$emit('close-modal', 'overTrainedAIs')"
    />
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import buttonMain from '@/components/atoms/button-main'
export default {
  components: {
    texts,
    buttonMain
  },
  methods: {
    checkPlan() {
      location.href = 'https://www.matrixflow.net/matrixflow/contact-basic-plan'
    }
  }
}
</script>

<style lang="scss" scoped>
.limit {
  &-wrap {
    min-width: adjustVW(768 - 48);
  }
  &-text {
    margin-bottom: $space-medium;
    white-space: pre-line;
  }
}
</style>
