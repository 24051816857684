<template>
  <!--
    アカウント情報・設定ページの左にあたるカードです
  -->
  <cardBase class="card">
    <texts
      class="first"
      size="large"
      :text="$t('account.setting.info.title')"
    />

    <div v-if="!accountInfoLoading" class="info">
      <texts
        class="info-title"
        :text="$t('account.setting.info.nickname')"
        color="gray"
        size="small"
      />

      <div class="info-inner">
        <texts v-if="!accountInfoLoading" :text="accountInfo.name" />
        <div class="info-bottom">
          <button class="button" @click="$emit('open-modal-nickname')">
            <texts
              size="12"
              :text="$t('account.setting.info.change')"
              color="gray"
            />
          </button>
        </div>
      </div>
    </div>

    <div v-if="!accountInfoLoading" class="info">
      <textWithTitle
        :title="$t('account.setting.info.accountID')"
        :text="accountInfo.accountId"
      />
    </div>

    <div class="info">
      <texts
        class="info-title"
        :text="$t('account.setting.info.emailadress')"
        color="gray"
        size="small"
      />

      <div class="info-inner">
        <texts :text="accountInfo.email" />
        <button class="button" @click="$emit('open-modal-email')">
          <texts
            size="12"
            color="gray"
            :text="$t('account.setting.info.change')"
          />
        </button>
      </div>

      <div v-if="!accountInfoLoading" class="email">
        <div
          v-if="this.accountInfo.emailVerified === false"
          class="email-verify"
        >
          <texts
            size="min"
            color="caution"
            :text="$t('account.setting.info.emailUnConfirmed')"
          />

          <button
            v-if="!accountInfo.emailVerified"
            class="email-verify-button"
            @click="sendVerifyEMail"
          >
            <texts
              class="text-button"
              size="min"
              color="caution"
              :text="$t('account.setting.info.sendVerificationemail')"
            />
          </button>
        </div>

        <div v-else-if="this.accountInfo.emailVerified">
          <texts
            size="min"
            color="emphasis"
            :text="$t('account.setting.info.emailConfirmed')"
          />
        </div>

        <div v-if="sendEmail" class="send-email">
          <text-box :text="$t('account.setting.info.finishedSendEmail')" />
        </div>
      </div>
    </div>

    <div class="info">
      <texts
        class="info-title"
        :text="$t('account.setting.info.plan')"
        color="gray"
        size="small"
      />

      <div class="info-inner">
        <texts
          v-if="!accountInfoLoading"
          :text="$t(`account.setting.info.accountPlan.` + accountInfo.plan)"
        />
        <div class="info-bottom">
          <button class="button" @click="toChangePlanPage()">
            <texts
              size="12"
              :text="$t('account.setting.info.change')"
              color="gray"
            />
          </button>
        </div>
      </div>
    </div>

    <div v-if="!accountInfoLoading" class="info">
      <texts
        class="info-title"
        color="gray"
        :text="$t('account.setting.info.planOption')"
        size="small"
      />

      <div v-if="accountOptionList" class="c-card-item">
        <div v-if="accountOptionList.length == 0" class="info-option-item">
          {{ $t('account.setting.info.nothing') }}
        </div>

        <texts
          v-for="(option, key) in accountOptionList"
          :key="key"
          class="info-option-item"
        >
          <span v-if="option.quantity > 0"> [{{ option.quantity }}] </span>
          {{ option.showName }}
        </texts>
      </div>
    </div>

    <div class="info-bottom">
      <button
        class="popoup-button"
        @click="$emit('open-modal-password', openPopupPassword)"
      >
        <texts
          class="popoup-text"
          :text="$t('account.setting.info.changePassword')"
          size="small"
        />
      </button>

      <button
        class="popoup-button"
        @click="$emit('open-modal-onetime-password', openPopupOTP)"
      >
        <texts
          class="popoup-text"
          :text="$t('account.setting.info.OTP')"
          size="small"
        />
      </button>
    </div>
  </cardBase>
</template>

<script>
import { sendVerifyEMail } from '@/lib/auth.js'
import texts from '@/components/atoms/text.vue'
import cardBase from '@/components/atoms/card-base.vue'
import textBox from '@/components/atoms/text-box'

import textWithTitle from '@/components/molecules/text-with-title.vue'

export default {
  components: {
    texts,
    cardBase,
    textWithTitle,
    textBox
  },
  data() {
    return {
      sendEmail: false,
      openPopupEmail: this.isShowPopupChangeAdress,
      openPopupPassword: this.isShowPopupChangePassword,
      openPopupOTP: this.isShowPopupOTP,
      authenticationEmail: false
    }
  },
  props: {
    i18n: String,
    isShowPopupChangeAdress: Boolean,
    isShowPopupOTP: Boolean,
    isShowPopupChangePassword: Boolean,
    accountInfo: Object,
    accountInfoLoading: Boolean
  },
  computed: {
    accountOptionList: function () {
      if (this.accountInfo.planDetail.options.length === 0) return []

      const language = this.i18n

      const optionList = this.accountInfo.planDetail.options.map((option) => {
        const showName =
          option.i18n[language] != null
            ? option.i18n[language]
            : this.$i18n.t(
                'account.setting.info.accountPlanOptions.' + option.name
              )

        return {
          name: option.name,
          quantity: option.quantity,
          showName
        }
      })

      return optionList
    }
  },
  methods: {
    toChangePlanPage() {
      location.href = 'https://www.matrixflow.net/matrixflow/contact-basic-plan'
    },
    async sendVerifyEMail() {
      try {
        const res = await sendVerifyEMail()
        if (res.result === 'success') {
          this.successSendingVerifyEMail = true
        }
      } finally {
        this.sendEmail = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.first {
  margin-bottom: $space-sub;
}
.card {
  height: fit-content;
  padding: $space-small $space-small $space-medium;
}
.info {
  margin-bottom: $space-medium;
  &-title {
    margin: 0 0 $space-text;
  }
  &-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  &-option {
    &-item {
      margin-bottom: $space-text;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
.send-email {
  margin-top: $space-small;
}
.email {
  margin: $space-text 0 0;
  &-verify {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-button {
      transition: opacity $transition-base;
      &:hover {
        opacity: 0.6;
      }
    }
  }
}
.button {
  width: adjustVW(40);
  height: adjustVW(20);
  border-radius: 9in;
  box-shadow: 0 0 0 adjustVW(1) $border-gray;
  transition: opacity $transition-base;
  &:hover {
    opacity: 0.6;
  }
}
.popoup {
  &-button {
    margin: 0 0 $space-base;
    transition: opacity $transition-base;
    &:hover {
      opacity: 0.6;
    }
    &:last-of-type {
      margin: 0;
    }
  }
  &-text {
    text-decoration: underline;
  }
}
.text-button {
  text-decoration: underline;
}
</style>
