<template>
  <div>
    <list-item-slot class="save-multi-wrap" :field="field" :items="items">
      <template v-slot:id="entry">
        <texts :text="'#' + entry.item" />
      </template>
      <template v-slot:name="entry">
        <texts :text="entry.item" />
      </template>
      <template v-slot:isSave="entry">
        <text-with-icon
          v-if="entry.item.flag"
          :text="$t('training.popup.save.saveButton')"
          iconName="trainRetrain"
          isButton
          @icon-click="reSelect(entry.item.id)"
        />
        <text-with-icon
          v-else
          :text="$t('training.popup.save.notSaveButton')"
          iconName="trainRetrain"
          color="caution"
          isButton
          @icon-click="reSelect(entry.item.id)"
        />
      </template>
      <template v-slot:expectedEffect="entry">
        <metrics-text
          :value="entry.item"
          :size="24"
          :percentage="checkPercentage"
          isBold
        />
      </template>
      <template v-slot:metrics="entry">
        <metrics-text
          :value="entry.item"
          :size="24"
          :percentage="checkPercentage"
          isBold
        />
      </template>
      <template v-slot:featureImportance="entry">
        <list-features :featureImportance="entry.item" />
      </template>
    </list-item-slot>
    <div v-if="overSaveFlag" class="save-multi-text">
      <texts
        color="caution"
        :text="
          $t('training.popup.save.caution.free.first', { count: limitCount })
        "
      />
      <texts
        color="caution"
        :text="$t('training.popup.save.caution.free.second')"
      />
      <div class="save-multi-text-row">
        <texts
          color="caution"
          :text="$t('training.popup.save.caution.free.beforeLink')"
        />
        <button @click="$emit('close-modal')">
          <a href="https://www.matrixflow.net/matrixflow/contact-basic-plan">
            <texts
              color="link-caution"
              :text="$t('training.popup.save.caution.free.link')"
            />
          </a>
        </button>
        <texts
          color="caution"
          :text="$t('training.popup.save.caution.free.last')"
        />
      </div>
    </div>
    <div>
      <button-main
        v-if="saveTrainedAis.length === 0"
        :text="$t('training.button.disposeAll')"
        type="caution"
        :isDisabled="disableClick"
        @click="$emit('discard-confirm')"
      />
      <button-main
        v-else
        :text="$t('training.button.saveComplete')"
        type="emphasis"
        :isDisabled="overSaveFlag || disableClick"
        @click="$emit('save-confirm')"
      />
      <button-main
        :text="$t('common.cancel')"
        type="sub"
        :isDisabled="disableClick"
        @click="$emit('close-modal')"
      />
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import metricsText from '@/components/atoms/metrics-text'
import textWithIcon from '@/components/molecules/text-with-icon'
import listItemSlot from '@/components/molecules/list-item-slot.vue'
import ButtonMain from '@/components/atoms/button-main.vue'
import listFeatures from '@/components/organisms/trained-ai-common/list-features'

export default {
  components: {
    texts,
    metricsText,
    textWithIcon,
    listItemSlot,
    ButtonMain,
    listFeatures
  },
  props: {
    sortedTrainedAis: Array,
    modelType: String,
    metricsDefs: Object,
    saveTrainedAis: Array,
    project: Object,
    loadingSave: Boolean,
    accountInfo: Object,
    numOwntrainedAIs: Number,
    numCreatingtrainedAIs: Number,
    disableClick: Boolean
  },
  computed: {
    field() {
      if (!this.mainMetrics) {
        return [
          {
            id: 0,
            key: 'id',
            label: '/',
            width: 24 + 24,
            listType: 'id'
          },
          {
            id: 1,
            key: 'name',
            label: this.$t('training.result.list.field.name'),
            width: 200 + 24
          },
          {
            id: 2,
            key: 'isSave',
            label: this.$t('training.result.list.field.toSaveOrNot'),
            width: 160 + 24
          },
          {
            id: 5,
            key: 'featureImportance',
            label: this.$t('training.result.list.field.featureImportance'),
            width: 262
          }
        ]
      } else if (this.project?.template?.expectedEffectName) {
        return [
          {
            id: 0,
            key: 'id',
            label: '/',
            width: 24 + 24,
            listType: 'id'
          },
          {
            id: 1,
            key: 'name',
            label: this.$t('training.result.list.field.name'),
            width: 200 + 24
          },
          {
            id: 2,
            key: 'isSave',
            label: this.$t('training.result.list.field.toSaveOrNot'),
            width: 160 + 24
          },
          {
            id: 3,
            key: 'expectedEffect',
            label: this.project.template.expectedEffectName,
            width: 200 + 24
          },
          {
            id: 4,
            key: 'metrics',
            label: this.metricsDefs[this.mainMetrics].name,
            width: 200 + 24
          },
          {
            id: 5,
            key: 'featureImportance',
            label: this.$t('training.result.list.field.featureImportance'),
            width: 262
          }
        ]
      } else {
        return [
          {
            id: 0,
            key: 'id',
            label: '/',
            width: 24 + 24,
            listType: 'id'
          },
          {
            id: 1,
            key: 'name',
            label: this.$t('training.result.list.field.name'),
            width: 200 + 24
          },
          {
            id: 2,
            key: 'isSave',
            label: this.$t('training.result.list.field.toSaveOrNot'),
            width: 160 + 24
          },
          {
            id: 3,
            key: 'metrics',
            label: this.metricsDefs[this.mainMetrics].name,
            width: 200 + 24
          },
          {
            id: 4,
            key: 'featureImportance',
            label: this.$t('training.result.list.field.featureImportance'),
            width: 262 + 24
          }
        ]
      }
    },
    // すべての学習結果の中で、保存するを選択したAIにだけisSaveのflagをtureにしている
    items() {
      const items = this.sortedTrainedAis.map((x, i) => {
        return {
          id: i + 1,
          name: this.sortedTrainedAis[i].name,
          isSave: {
            flag: this.saveTrainedAis.some((item) => item.id === x.id),
            id: i
          },
          metrics: x.summary?.metrics[0][this.mainMetrics],
          featureImportance: x.summary?.featureImportance
        }
      })
      return items
    },
    // プロジェクトテンプレート指定のmetricsがある場合はそれで、なければtest_accuracyこれもなければtest_overall_accuracy
    mainMetrics() {
      const isTemplateMetrics = this.project?.template?.metrics
      if (isTemplateMetrics) {
        return isTemplateMetrics
      } else if (
        'test_accuracy' in (this.sortedTrainedAis[0].summary?.metrics[0] ?? {})
      ) {
        return 'test_accuracy'
      } else if (
        'test_overall_accuracy' in
        (this.sortedTrainedAis[0].summary?.metrics[0] ?? {})
      ) {
        return 'test_overall_accuracy'
      } else if (
        'test_r2' in (this.sortedTrainedAis[0].summary?.metrics[0] ?? {})
      ) {
        return 'test_r2'
      } else {
        return null
      }
    },
    limitCount() {
      return this.maxTrainedAIs - this.numOwntrainedAIs
    },
    maxTrainedAIs() {
      return this.accountInfo.planDetail.numModel
    },
    overSaveFlag() {
      return this.saveTrainedAis.length > this.limitCount
    },
    checkPercentage() {
      return this.metricsDefs[this.mainMetrics].percentage
    }
  },
  methods: {
    reSelect(id) {
      this.$emit('re-select', id)
    },
    clipObject(items) {
      const keyList = Object.keys(items)
      const clipObject = []
      for (const key in keyList) {
        if (key > 2) {
          return clipObject
        } else {
          clipObject.push(keyList[key])
        }
      }
      return clipObject
    }
  }
}
</script>

<style lang="scss" scoped>
.save-multi {
  &-wrap {
    margin: 0 0 $space-medium;
  }
  &-list {
    display: flex;
    align-items: center;
    margin: 0 0 $space-base;
    &::before {
      content: '';
      display: block;
      width: adjustVW(12);
      height: adjustVW(12);
      margin: 0 $space-base 0 0;
      background: $key-color;
      border-radius: 9in;
    }
    &:last-child {
      margin: 0;
    }
    &-inner {
      overflow: hidden;
      width: calc(100% - #{adjustVW(20)});
      color: $text-main;
      font-size: $text-base;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      @include text-crop;
    }
  }
  &-none-feature-importance {
    white-space: pre-line;
  }
  &-text {
    margin: 0 0 $space-small;
    &-row {
      display: flex;
    }
  }
}
</style>
